import './Fortbnb.css';

function Fortbnb() {

    return (
        <></>
    )
}

export default Fortbnb;
