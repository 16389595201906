import './AboutPage.css';

function AboutPage() {
    return (
        <div className='about-page'>
            <h1>About Me</h1>
        </div>
    )
}

export default AboutPage;
